
import { Component, Vue } from "vue-property-decorator";
import { tokenService } from "@/services/TokenService";
import { Header } from '@/components';
import { decode, encode } from '@/services/Base64Coder'

@Component({
  components: { Header }
})

export default class Home extends Vue {

  get initialDataFetched() {
    return this.$store.state.initialDataFetched
  }

  get routeFullPath() {
    return this.$route.fullPath
  }

  async created() {
    let query = '';
    let pathName = '/'
    if (window.location.pathname)
      pathName = window.location.pathname;

    if (window.location.search)
      query = window.location.search;
    
    const urlParams = new URLSearchParams(query);
    const state = urlParams.get('state');
    if (state) {
      let str = decode(state).split('?');
      if(str[0]) pathName = str[0];
      if(str[1]) query = '?' + str[1]
    }

    const queryParamMaybe = urlParams.get('code');

    if (!queryParamMaybe && !tokenService.accessToken) {
      this.$router.push("/login" + '?state=' + encode(pathName + query));
    }

    if (queryParamMaybe) {
      const authResponse = await this.$store.dispatch("GetAccessToken", { query: queryParamMaybe.toString() })
      tokenService.setAccessToken(authResponse.accessToken)
      this.$router.push(pathName)
    }

    await this.$store.dispatch("GetCurrentUser");

    if (this.$store.state.appDataUnauthorized) {
      this.$router.push("/login" + '?state=' + encode(pathName + query))
    }

    this.setInitialData()
  }

  setInitialData() {
    this.$store.commit({
      type: "SET_STORE_FIELDS",
      payload: {
        field: ['initialDataFetched'],
        value: [true],
      }
    })
  }
}
